import axios from './axios.js'
import AuthService from './authService.js';
import store from '@/config/store/index.js';

function objToQuery(obj) {
    let query = '';
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
        }
        else {
            query += propName + '=' + obj[propName] + '&'
        }
    }
    query =  query.slice(0, -1);
    return query;
}

const CompanyService = {
    async companyBasicDetails(inn) {
        let response = await axios.get('/company/get-basic-details?inn=' + inn);
        return response;
    },
    async companyTenders(inn) {
        let response = await axios.get('/company/get-company-tenders?inn=' + inn);
        return response;
    },
    async companyOrders(inn) {
        let response = await axios.get('/company/get-company-orders?inn=' + inn);
        return response;
    },
    async companyTradeMarks(inn) {
        let response = await axios.get('/company/get-company-trademarks?inn=' + inn);
        return response;
    },
    async companyFounders(inn) {
        let response = await axios.get('/billable/get-company-founders?inn=' + inn, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async searchCompanies(searchText, limit, skip) {

        let response = await axios.get(`/search/companies?keyword=` + searchText + '&limit=' + limit + '&skip=' + skip);
        return response;
    },
    async searchFounders(searchText, limit, skip) {

        let response = await axios.get(`/search/founders?keyword=` + searchText + '&limit=' + limit + '&skip=' + skip);
        return response;
    },
    async searchForSuggestion(searchText) {
        let response = await axios.get(`/search/suggestion?keyword=` + searchText);
        return response;
    },
    async tradeMark(id) {
        let response = await axios.get(`/company/get-trademark-details?id=` + id);
        return response;
    },
    async topCompaniesByAuthorizedFund() {
        let response = await axios.get('/reporting/get-top-companies-by-authorized-fund');
        return response;
    },
    async topCompaniesByTradeMarksCount() {
        let response = await axios.get('/reporting/get-top-companies-by-trademarks-count');
        return response;
    },
    async generateReportFile(obj) {
        let query = objToQuery(obj);
        let response = await axios.get('reporting/generate-report-file?' + query,{headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async generateLicenseReportFile(obj) {
        let query = objToQuery(obj);
        let response = await axios.get('reporting/generate-license-report-file?'+ query,{headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getLicensiesLegal(obj) {
        let query = objToQuery(obj);
        let headers = {};
        if (AuthService.Authorization()){
            headers = {Authorization: AuthService.Authorization()};
        }
        let response = await axios.get('billable/company-licenses-data-for-legal?' + query, {headers: headers});
        return response;
    },
    async getLicensiesIndividuals(obj) {
        let query = objToQuery(obj);
        let response = await axios.get('billable/company-licenses-data-for-individuals?' + query, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getCompanyRegistrationInfo(inn) {
        let response = await axios.get('billable/company-registration-info?inn=' + inn, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getIndividualRegistrationInfo(pinf) {
        let response = await axios.get('billable/individual-registration-info?pinfl=' + pinf, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getCompanyBranches(inn) {
        let response = await axios.get('company/get-company-branches?inn=' + inn);
        return response;
    },
    async getLicenseDetails(id) {
        let query = objToQuery(id);
        let response = await axios.get('billable-data-history/company-licenses-details?' + query, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async companyBasicDetailsHistory(inn) {
        let response = await axios.get('/billable-data-history/company-details?targetInnPinfl=' + inn);
        return response;
    },
    async companyFoundersHistory(inn) {
        let response = await axios.get('/billable-data-history/company-founders?targetInnPinfl=' + inn,{headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getLicensiesLegalHistory(obj) {
        let query = objToQuery(obj);
        let response = await axios.get('/billable-data-history/company-licenses-data-for-legal?' + query, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getLicensiesIndividualsHistory(obj) {
        let query = objToQuery(obj);
        let response = await axios.get('/billable-data-history/company-licenses-data-for-individuals?' + query, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getCompanyRegistrationInfoHistory(inn) {
        let response = await axios.get('/billable-data-history/company-registration-info?inn=' + inn, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getIndividualRegistrationInfoHistory(pinf) {
        let response = await axios.get('/billable-data-history/individual-registration-info?pinfl=' + pinf, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getVisitorFinanceInfo() {
        let response = await axios.get('visitor/finance-info', {headers: {Authorization: AuthService.Authorization()}});
        return response;
    },
    async getCompanyData(obj){
        let response = await axios.post('/billable/company-data',obj, {headers: {Authorization: AuthService.Authorization()}});
        return response;
    }
}

export default CompanyService;